import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "./index.css";

import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// setting up firebase config
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTFE_0_2rmWAR6rEozoC1VB1MZsxVXVGI",
  authDomain: "satjam-fe.firebaseapp.com",
  projectId: "satjam-fe",
  storageBucket: "satjam-fe.appspot.com",
  messagingSenderId: "1069049888527",
  appId: "1:1069049888527:web:614c74d3068906b9d0487a",
  measurementId: "G-4S9BXHY7MH",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
initializeApp(firebaseConfig);
const appRoot = document.getElementById("root");
const root = ReactDOM.createRoot(appRoot);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
