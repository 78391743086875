import axios from "axios";
import { baseURL } from "./base.service";

const register = (username, email, password) => {
  return axios.post(`${baseURL}/auth/signup`, {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(`${baseURL}/auth/signin`, {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const module = {
  register,
  login,
  logout,
};

export default module;
