import { LOGOUT } from "../types";

import AuthService from "../../services/auth.service";

export const logout = () => {
  return (dispatch) => {
    AuthService.logout();

    dispatch({
      type: LOGOUT,
    });
  };
};
