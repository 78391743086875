export const About = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>சத்தியமேஜெயம்</h2>
          <p>
            சத்தியமேஜெயம் <span>என்றால் என்ன?</span>
          </p>
        </div>

        <div className="row gy-4">
          <div
            className="col-lg-7 p-0 position-relative about-img"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="greensky-video-container">
              <video
                controls=""
                muted
                playsInline
                loop
                autoPlay={true}
                name="media"
              >
                <source
                  muted
                  playsInline
                  loop
                  autoPlay={true}
                  className="greensky-video-hosted greensky-html5-video"
                  src="/assets/videos/Clouds.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="greensky-overlay"></div>
              <div className="greensky-rose-overlay"></div>
            </div>
          </div>
          <div
            className="col-lg-5 d-flex align-items-end"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="content ps-0 ps-lg-5">
              <p className="fst-italic">
                "சத்தியமேஜெயம்" என்ற சொல்லுக்கு எஜமான் கொடுத்த விளக்கம்:
              </p>
              <p>
                சத்தியம் என்றால் சிவம், ஜெயம் என்றால் சக்தி. சிவசக்தி இணைந்து
                இருப்பதுதான் சத்தியமேஜெயம். ஆதி காலம் முதல் இந்த நாள் வரை
                ஒவ்வோர் உயிருக்குள்ளும் சிவசக்தி இணைந்து ஆதியாகிய எஜமானை
                வணங்கிக் கொண்டிருக்கின்றன.
              </p>
              <p>
                உங்கள் நினைவை இந்த வணக்கத்துடன் இணைத்தால் அவர் உங்கள்
                கைப்பிடித்து எஜமானுடன் கொண்டு போய் இணைத்து விடுவார். ஆதலால்
                உங்கள் நினைவை சிவசக்தியுடன், அதாவது ஜோதியுடன், அதாவது
                எம்பெருமானுடன், அதாவது குருவுடன் அதாவது சத்யமேஜெயத்துடன் இணைத்து
                விடுங்கள்.
              </p>
              <p>
                இவ்வுலகிற்கு வந்திருக்கும் இந்த இறுதி குருவின் பெயர்
                சத்தியமேஜெயம். நீங்கள் எஜமானை அடைய விரும்பினால், அவருடன் இணைய
                விரும்பினால், சத்தியமேஜெயத்தை, அதாவது குருவை, அதாவது
                எம்பெருமானை, அதாவது ஜோதியை, அதாவது சிவசக்தியை நாடினால் எஜமானுடன்
                இணைவது உறுதி! உறுதி!! உறுதி!!!
              </p>
              <p>
                சிவசக்தி என்றால் அல்லாஹ் ரசூல் எனவும் பிதா சுதன் எனவும் பொருள்
                கொள்ளவும்.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
