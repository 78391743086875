import { useEffect } from "react";
import PureCounter from "@srexi/purecounterjs";

export const Visitor = () => {
  useEffect(() => {
    new PureCounter();
  }, []);

  return (
    <section id="stats-counter" className="stats-counter">
      <div className="container" data-aos="zoom-out">
        <div className="row gy-4">
          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <span
                data-purecounter-start="0"
                data-purecounter-end="11"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              <p>புத்தகங்கள்</p>
            </div>
          </div>
          {/* <!-- End Stats Item --> */}

          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <span
                data-purecounter-start="0"
                data-purecounter-end="1000"
                data-purecounter-duration="1"
                data-purecounter-currency="+"
                className="purecounter"
              ></span>
              <p>ஒலிப்பதிவுகள்</p>
            </div>
          </div>
          {/* <!-- End Stats Item --> */}

          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <span
                data-purecounter-start="0"
                data-purecounter-end="7777"
                data-purecounter-duration="1"
                data-purecounter-currency="+"
                className="purecounter"
              ></span>
              <p>வசனங்கள்</p>
            </div>
          </div>
          {/* <!-- End Stats Item --> */}

          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <span
                data-purecounter-start="0"
                data-purecounter-end="1"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              <p>மூலம்</p>
            </div>
          </div>
          {/* <!-- End Stats Item --> */}
        </div>
      </div>
    </section>
  );
};
