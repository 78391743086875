import { useEffect } from "react";

// import AOS from "aos";
// import "aos/dist/aos.css";
import GLightbox from "glightbox";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  A11y,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

export const Gallery = () => {
  useEffect(() => {
    //Initiate glightbox
    GLightbox({
      selector: ".glightbox",
    });

    SwiperCore.use([Autoplay]);
  }, []);

  return (
    <section id="gallery" className="gallery section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>சத்தியமேஜெயம்</h2>
          <p>
            படத்<span>தொகுப்பு</span>
          </p>
        </div>

        <Swiper
          modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y]}
          centeredSlides={true}
          speed={400}
          loop={true}
          autoplay={true}
          spaceBetween={5}
          slidesPerView={3}
          navigation
          pagination={{ clickable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
          className="gallery-slider"
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
        >
          <SwiperSlide>
            <a
              className="glightbox"
              data-gallery="images-gallery"
              href="assets/img/gallery/gallery-1.jpg"
            >
              <img
                src="assets/img/gallery/gallery-1.jpg"
                className="img-fluid"
                alt=""
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className="glightbox"
              data-gallery="images-gallery"
              href="assets/img/gallery/gallery-2.jpg"
            >
              <img
                src="assets/img/gallery/gallery-2.jpg"
                className="img-fluid"
                alt=""
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className="glightbox"
              data-gallery="images-gallery"
              href="assets/img/gallery/gallery-3.jpg"
            >
              <img
                src="assets/img/gallery/gallery-3.jpg"
                className="img-fluid"
                alt=""
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className="glightbox"
              data-gallery="images-gallery"
              href="assets/img/gallery/gallery-4.jpg"
            >
              <img
                src="assets/img/gallery/gallery-4.jpg"
                className="img-fluid"
                alt=""
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className="glightbox"
              data-gallery="images-gallery"
              href="assets/img/gallery/gallery-5.jpg"
            >
              <img
                src="assets/img/gallery/gallery-5.jpg"
                className="img-fluid"
                alt=""
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className="glightbox"
              data-gallery="images-gallery"
              href="assets/img/gallery/gallery-6.jpg"
            >
              <img
                src="assets/img/gallery/gallery-6.jpg"
                className="img-fluid"
                alt=""
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className="glightbox"
              data-gallery="images-gallery"
              href="assets/img/gallery/gallery-7.jpg"
            >
              <img
                src="assets/img/gallery/gallery-7.jpg"
                className="img-fluid"
                alt=""
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className="glightbox"
              data-gallery="images-gallery"
              href="assets/img/gallery/gallery-8.jpg"
            >
              <img
                src="assets/img/gallery/gallery-8.jpg"
                className="img-fluid"
                alt=""
              />
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

{
  /* <section id="gallery" className="gallery section-bg">
  <div className="container" data-aos="fade-up">
    <div className="section-header">
      <h2>gallery</h2>
      <p>
        Check <span>Our Gallery</span>
      </p>
    </div>

    <div className="gallery-slider swiper">
      <div className="swiper-wrapper align-items-center">
        <div className="swiper-slide">
          <a
            className="glightbox"
            data-gallery="images-gallery"
            href="assets/img/gallery/gallery-1.jpg"
          >
            <img
              src="assets/img/gallery/gallery-1.jpg"
              className="img-fluid"
              alt=""
            />
          </a>
        </div>
        <div className="swiper-slide">
          <a
            className="glightbox"
            data-gallery="images-gallery"
            href="assets/img/gallery/gallery-2.jpg"
          >
            <img
              src="assets/img/gallery/gallery-2.jpg"
              className="img-fluid"
              alt=""
            />
          </a>
        </div>
        <div className="swiper-slide">
          <a
            className="glightbox"
            data-gallery="images-gallery"
            href="assets/img/gallery/gallery-3.jpg"
          >
            <img
              src="assets/img/gallery/gallery-3.jpg"
              className="img-fluid"
              alt=""
            />
          </a>
        </div>
        <div className="swiper-slide">
          <a
            className="glightbox"
            data-gallery="images-gallery"
            href="assets/img/gallery/gallery-4.jpg"
          >
            <img
              src="assets/img/gallery/gallery-4.jpg"
              className="img-fluid"
              alt=""
            />
          </a>
        </div>
        <div className="swiper-slide">
          <a
            className="glightbox"
            data-gallery="images-gallery"
            href="assets/img/gallery/gallery-5.jpg"
          >
            <img
              src="assets/img/gallery/gallery-5.jpg"
              className="img-fluid"
              alt=""
            />
          </a>
        </div>
        <div className="swiper-slide">
          <a
            className="glightbox"
            data-gallery="images-gallery"
            href="assets/img/gallery/gallery-6.jpg"
          >
            <img
              src="assets/img/gallery/gallery-6.jpg"
              className="img-fluid"
              alt=""
            />
          </a>
        </div>
        <div className="swiper-slide">
          <a
            className="glightbox"
            data-gallery="images-gallery"
            href="assets/img/gallery/gallery-7.jpg"
          >
            <img
              src="assets/img/gallery/gallery-7.jpg"
              className="img-fluid"
              alt=""
            />
          </a>
        </div>
        <div className="swiper-slide">
          <a
            className="glightbox"
            data-gallery="images-gallery"
            href="assets/img/gallery/gallery-8.jpg"
          >
            <img
              src="assets/img/gallery/gallery-8.jpg"
              className="img-fluid"
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="swiper-pagination"></div>
    </div>
  </div>
</section>; */
}
