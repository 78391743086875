import axios from "axios";
import authHeader from "./auth-header";

// export const baseURL = "http://localhost:8080/api";
export const baseURL = "https://tame-puce-sparrow-tutu.cyclic.app/api";

const instFetchJSON = axios.create({
  baseURL,
});

instFetchJSON.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instFetchJSON.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const fetchJSON = ({
  method = "get",
  url,
  payload = null,
  responseType = "json",
}) => {
  let request = {
    method,
    url,
    responseType,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  // if not a get call
  if (payload) {
    request.data = { ...payload };
  }

  return instFetchJSON(request);
};
