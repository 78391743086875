import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { logout } from "../../actions/auth";
import { clearMessage } from "../../actions/message";

export const Header = () => {
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const refHeader = useRef();
  const refNavBar = useRef();

  const dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    const selectHeader = refHeader.current;
    if (selectHeader) {
      document.addEventListener("scroll", () => {
        window.scrollY > 100
          ? selectHeader.classList.add("sticked")
          : selectHeader.classList.remove("sticked");
      });
    }

    /**
     * Preloader
     */
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      window.addEventListener("load", () => {
        preloader.remove();
      });
    }

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = document.querySelectorAll("#navbar a");

    function navbarlinksActive() {
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;

        let section = document.querySelector(navbarlink.hash);
        if (!section) return;

        let position = window.scrollY + 200;

        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    }
    window.addEventListener("load", navbarlinksActive);
    document.addEventListener("scroll", navbarlinksActive);

    /**
     * Mobile nav toggle
     */
    const mobileNavShow = document.querySelector(".mobile-nav-show");
    const mobileNavHide = document.querySelector(".mobile-nav-hide");

    document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
      el.addEventListener("click", function (event) {
        event.preventDefault();
        mobileNavToogle();
      });
    });

    function mobileNavToogle() {
      document.querySelector("body").classList.toggle("mobile-nav-active");
      mobileNavShow.classList.toggle("d-none");
      mobileNavHide.classList.toggle("d-none");
    }

    /**
     * Hide mobile nav on same-page/hash links
     */
    document.querySelectorAll("#navbar a").forEach((navbarlink) => {
      if (!navbarlink.hash) return;

      let section = document.querySelector(navbarlink.hash);
      if (!section) return;

      navbarlink.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });

    /**
     * Toggle mobile nav dropdowns
     */
    const navDropdowns = document.querySelectorAll(".navbar .dropdown > a");

    navDropdowns.forEach((el) => {
      el.addEventListener("click", function (event) {
        if (document.querySelector(".mobile-nav-active")) {
          event.preventDefault();
          this.classList.toggle("active");
          this.nextElementSibling.classList.toggle("dropdown-active");

          let dropDownIndicator = this.querySelector(".dropdown-indicator");
          dropDownIndicator.classList.toggle("bi-chevron-up");
          dropDownIndicator.classList.toggle("bi-chevron-down");
        }
      });
    });
  }, []);

  useEffect(() => {
    // clear message when changing location
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage());
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (currentUser) {
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowAdminBoard(false);
    }
  }, [currentUser]);

  const logoutUser = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <header
      ref={refHeader}
      className="header fixed-top d-flex align-items-center"
    >
      <div className="container d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center me-auto me-lg-0">
          {/* Uncomment the line below if you also wish to use an image logo
          <img src="assets/img/logo.png" alt=""> */}
          <h1>சத்தியமேஜெயம்</h1>
        </a>

        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a href="/#hero">Home</a>
            </li>
            <li className="dropdown">
              <a herf="/#about">
                <span>About Us</span>
                <i className="bi bi-chevron-down dropdown-indicator"></i>
              </a>
              <ul>
                <li>
                  <a href="/#about">What is Sathiyameyjeyam?</a>
                </li>
                <li>
                  <a href="/#why-us">Quick Links</a>
                </li>
                <li>
                  <a href="/#stats-counter">Stats</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/#media">Recordings</a>
            </li>
            <li className="hide-for-some-time">
              <a href="/#events">Events</a>
            </li>
            <li className="hide-for-some-time">
              <a href="/#gallery">Gallery</a>
            </li>
            {currentUser ? (
              <>
                <li>
                  <Link to={"/profile"}>Profile</Link>
                </li>
                <li>
                  <a href="#" onClick={logoutUser}>
                    Logout
                  </a>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link to={"/login"}>Login</Link>
                </li>

                <li className="nav-item">
                  <Link to={"/register"}>Sign Up</Link>
                </li>
              </>
            )}
            {/* <li className="hide-for-some-time dropdown">
              <a href="#">
                <span>Drop Down</span>
                <i className="bi bi-chevron-down dropdown-indicator"></i>
              </a>
              <ul>
                <li>
                  <a href="#">Drop Down 1</a>
                </li>
                <li className="dropdown">
                  <a href="#">
                    <span>Deep Drop Down</span>
                    <i className="bi bi-chevron-down dropdown-indicator"></i>
                  </a>
                  <ul>
                    <li>
                      <a href="#">Deep Drop Down 1</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 2</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 3</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 4</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 5</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Drop Down 2</a>
                </li>
                <li>
                  <a href="#">Drop Down 3</a>
                </li>
                <li>
                  <a href="#">Drop Down 4</a>
                </li>
              </ul>
            </li> */}
            {/* <li className="hide-for-some-time">
              <a href="#contact">Contact</a>
            </li> */}
          </ul>
        </nav>
        {/* .navbar */}

        {/* <a className="btn-book-a-table" href="#book-a-table">Book a Table</a> */}
        <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
      </div>
    </header>
  );
};
