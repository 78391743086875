import { useEffect, useState } from "react";

import { About } from "./About";
import { Whyus } from "./Whyus";
import { Visitor } from "./Visitor";
import { Media } from "./Media";
import { Gallery } from "./Gallery";
// import { Contact } from "./Contact";
import { Testimonials } from "./Testimonials";
import { Events } from "./Events";

import { getPublicContent } from "../services/user.service";

const Home = () => {
  // TEMP ON HOLD
  const setContent = useState("")[1];

  useEffect(() => {
    getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setContent(_content);
      }
    );
  }, []);

  return (
    <main id="main">
      <About />
      <Whyus />
      <Visitor />
      <Media />
      <Gallery />
      {/* TEMP ON HOLD - Hiding for time being */}
      {/* <Events />
      <Testimonials />
      <Contact /> */}
    </main>
  );
};

export default Home;
