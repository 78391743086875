import {
  EMPERMAN_VASANAM,
  EMPERMAN_VASANAM_CLICK,
  RAHMAN_VASANAM,
  RAHMAN_VASANAM_CLICK,
  QUESTION_ANSWER,
  QUESTION_ANSWER_CLICK,
} from "../../messages";

export const Whyus = () => {
  return (
    <section id="why-us" className="why-us section-bg">
      <div className="container" data-aos="fade-up">
        <div className="row gy-4">
          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="why-box">
              <h3>சத்தியமேஜெயத்தின் வேண்டுதல்!</h3>
              <ul>
                <li>வேண்டுதல் இல்லாத சத்தியமேஜெயத்தின் வேண்டுதல்!</li>
                <li>
                  இயற்கை மஹா சக்தியே! எஜமானே! <br />
                  கற்பனைக்கும் மனோவாக்கிற்கும் அகப்படாத <br />
                  எல்லா வல்லமையும் உள்ள உள்ளமைப்பொருளே!
                </li>
                <li>
                  எனது அறிவாலும், அனுபவத்தாலும் அவதிப்படுகிறேன், <br />
                  உனது அறிவு தந்து கரையேற்று கதறுகிறேன்!
                </li>
                <li>
                  இறை அறிவு, மனித அறிவு - அறிவது எப்படி <br />
                  ஏக இறைவனை!
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 d-flex align-items-center">
            <div className="row gy-4">
              <div className="col-xl-4" data-aos="fade-up" data-aos-delay="200">
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-clipboard-data"></i>
                  <h4>{EMPERMAN_VASANAM}</h4>
                  <p>{EMPERMAN_VASANAM_CLICK}</p>
                </div>
              </div>
              {/* <!-- End Icon Box --> */}

              <div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-gem"></i>
                  <h4>{RAHMAN_VASANAM}</h4>
                  <p>{RAHMAN_VASANAM_CLICK}</p>
                </div>
              </div>
              {/* <!-- End Icon Box --> */}

              <div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-inboxes"></i>
                  <h4>{QUESTION_ANSWER}</h4>
                  <p>{QUESTION_ANSWER_CLICK}</p>
                </div>
              </div>
              {/* <!-- End Icon Box --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
