module.exports = {
  EMPERMAN_VASANAM: "எம்பெருமான் வசனம்",
  EMPERMAN_VASANAM_CLICK:
    "எம்பெருமான் தொடர்பான அனைத்து வசனங்களையும் படிக்க இந்த பகுதியை தேர்வுசெய்யவும்.",
  RAHMAN_VASANAM: "ரகுமான் வசனம்",
  RAHMAN_VASANAM_CLICK:
    "ரகுமான் தொடர்பான அனைத்து வசனங்களையும் படிக்க இந்த பகுதியை தேர்வுசெய்யவும்.",
  QUESTION_ANSWER: "கேள்வி & பதில்கள்",
  QUESTION_ANSWER_CLICK: "சத்தியமேஜெயம் தொடர்பான கேள்வி பதில்களின் தொகுப்பு.",
};
