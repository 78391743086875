import { useCallback, useEffect, useState } from "react";
import { fetchBooks } from "../../services/book.service";

// const books = [
//   {
//     id: "bk001",
//     name: "இறைவன் செய்த இறைப் பயணம்",
//     release: "29/08/1999",
//     path: "/",
//   },
//   { id: "bk002", name: "யாரப்பா முஸ்லீம்?", release: "23/12/2000", path: "/" },
//   { id: "bk003", name: "யாரப்பா இந்து?", release: "23/12/2000", path: "/" },
//   {
//     id: "bk004",
//     name: "யாரப்பா கிறிஸ்தவர்?",
//     release: "30/12/2001",
//     path: "/",
//   },
//   {
//     id: "bk005",
//     name: "மனிதனுக்கு ஏதப்பா மரணம்?",
//     release: "06/06/2002",
//     path: "/",
//   },
//   {
//     id: "bk006",
//     name: "இயற்கை மார்க்கம் இஸ்லாம்; அறிவை நம்பாதே!",
//     release: "01/07/2003",
//     path: "/",
//   },
//   {
//     id: "bk007",
//     name: "சத்தியத்தின் சத்தியம்",
//     release: "18/08/2003",
//     path: "/",
//   },
//   { id: "bk008", name: "ஆதியோனோதிய போதனை", release: "18/08/2003", path: "/" },
//   { id: "bk009", name: "தூயோனின் தூது", release: "14/10/2005", path: "/" },
//   { id: "bk010", name: "ஆதி முதல் ஆதி வரை", release: "31/12/2006", path: "/" },
//   {
//     id: "bk011",
//     name: "எஜமானின் முத்துக்கள் சத்தியமேஜெயத்தின் சொத்துக்கள்",
//     release: "03/06/2007",
//     path: "/",
//   },
// ];

export const MediaBook = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const getBooks = async () => {
      const { data = null, error = null } = await fetchBooks();

      if (data) {
        setBooks(data);
      }
    };

    getBooks();
  }, []);

  // fucntion which render the header for the media
  const renderBookHeader = useCallback(() => {
    return (
      <div className="sj-media-head">
        <div className="sj-media-item">
          <div className="sj-media-icon"></div>
          <div className="sj-media-name">புத்தகப் பெயர்</div>
          <div className="sj-media-pages">வெளியீட்டுத் தேதி</div>
          <div className="sj-media-more"></div>
        </div>
      </div>
    );
  }, []);

  // function which renders every child row for the given media
  const renderBooks = useCallback(({ id, name, release }) => {
    return (
      <div key={id} className="sj-media-item">
        <div className="sj-media-icon">
          <i className="bi bi-book"></i>
        </div>
        <div className="sj-media-name">{name}</div>
        <div className="sj-media-pages">{release}</div>
        <div className="sj-media-more">
          <i className="bi bi-three-dots"></i>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="row gy-5 sj-media-collection">
      {renderBookHeader()}
      <div className="sj-media-body mt-3">
        {books.map((book) => renderBooks(book))}
      </div>
    </div>
  );
};
