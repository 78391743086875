import { REGISTER_SUCCESS, REGISTER_FAIL } from "../types";
import { setMessage } from "../message";

import AuthService from "../../services/auth.service";

export const register = (username, email, password) => {
  return (dispatch) => {
    return AuthService.register(username, email, password)
      .then((response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch(setMessage(response.data.message));

        return Promise.resolve();
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch(setMessage(message));

        return Promise.reject();
      });
  };
};
