import axios from "axios";
import authHeader from "./auth-header";
import { baseURL } from "./base.service";

export function getPublicContent() {
  return axios.get(`${baseURL}/test/all`);
}

export function getUserBoard() {
  return axios.get(`${baseURL}/test/user`, { headers: authHeader() });
}

export function getModeratorBoard() {
  return axios.get(`${baseURL}/test/mod`, { headers: authHeader() });
}

export function getAdminBoard() {
  return axios.get(`${baseURL}/test/admin`, { headers: authHeader() });
}

// export default {
//   getPublicContent,
//   getUserBoard,
//   getModeratorBoard,
//   getAdminBoard,
// };
