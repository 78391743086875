import { MediaBook } from "./MediaBook";

export const Media = () => {
  return (
    <section id="media" className="media">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>சத்தியமேஜெயம்</h2>
          <p>
            சத்தியமேஜெய <span>பதிவுகள்</span>
          </p>
        </div>

        <ul
          className="nav nav-tabs d-flex justify-content-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <li className="nav-item">
            <span
              className="nav-link active show"
              data-bs-toggle="tab"
              data-bs-target="#media-books"
            >
              <h4>புத்தகங்கள்</h4>
            </span>
          </li>
          {/* <!-- End tab nav item --> */}

          <li className="nav-item">
            <span
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#media-audio"
            >
              <h4>ஒலிப்பதிவு</h4>{" "}
            </span>
            {/* <!-- End tab nav item --> */}
          </li>

          <li className="nav-item">
            <span
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#media-video"
            >
              <h4>காணொலி</h4>
            </span>
          </li>
          {/* <!-- End tab nav item --> */}

          <li className="nav-item">
            <span
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#media-picture"
            >
              <h4>பாடல்கள்</h4>
            </span>
          </li>
          {/* <!-- End tab nav item --> */}
        </ul>

        <div className="tab-content" data-aos="fade-up" data-aos-delay="300">
          <div className="tab-pane fade active show" id="media-books">
            <div className="tab-header text-center"></div>

            <MediaBook />
          </div>
          {/* <!-- End Starter media Content --> */}

          <div className="tab-pane fade" id="media-audio">
            <div className="tab-header text-center"></div>

            <div className="row gy-5">
              <div className="col-lg-4 media-item">
                <h4>ஒலிப்பதிவு</h4>
                <p className="description">Under Construction</p>
              </div>
              {/* <!-- media Item --> */}
            </div>
          </div>
          {/* <!-- End Breakfast media Content --> */}

          <div className="tab-pane fade" id="media-video">
            <div className="tab-header text-center"></div>

            <div className="row gy-5">
              <div className="col-lg-4 media-item">
                <h4>காணொலி</h4>
                <p className="description">Under Construction</p>
              </div>
              {/* <!-- media Item --> */}
            </div>
          </div>
          {/* <!-- End Lunch media Content --> */}

          <div className="tab-pane fade" id="media-picture">
            <div className="tab-header text-center"></div>

            <div className="row gy-5">
              <div className="col-lg-4 media-item">
                <h4>பாடல்கள்</h4>
                <p className="description">Under Construction</p>
              </div>
              {/* <!-- media Item --> */}
            </div>
          </div>
          {/* <!-- End Dinner media Content --> */}
        </div>
      </div>
    </section>
  );
};
