import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const Hero = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
    AOS.refresh();
  });

  return (
    <section id="hero" className="hero d-flex align-items-center section-bg">
      <div className="container">
        <div className="row justify-content-between gy-5">
          <div className="col-lg-7 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
            <h2 data-aos="fade-up" className="title-main">
              சத்தியமேஜெயம்
            </h2>
            <p data-aos="fade-up" className="slogan-main" data-aos-delay="100">
              ஆட்சி அனைத்தும் நீதியின் அரசர் எஜமானுடையது
            </p>
            <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
              <a href="#" className="btn-watch-video d-flex align-items-center">
                <i className="bi bi-play-circle"></i>
                <span>இறைவனை பற்றி</span>
              </a>
            </div>
          </div>
          <div className="col-lg-5 order-1 order-lg-2 text-center text-lg-start">
            <img
              src="assets/img/hero-img.png"
              className="img-fluid"
              alt=""
              data-aos="zoom-out"
              data-aos-delay="300"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
